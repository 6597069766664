import React from "react";
import { Bg, Text } from "../components/ui";
import Layout from "../components/reusable/Layout";
import { Button, Grid, Stack } from "@mui/material";
import Seo from "../components/reusable/Seo";
import {
  CompaniesSection,
  ContactSection,
  HeroSection,
} from "../styles/pages/home";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import Carousel from "../components/reusable/Carousel";
import { DocumentTextClock } from "styled-icons/fluentui-system-filled";
import { User } from "styled-icons/fa-regular";
import { CreditCard } from "styled-icons/bootstrap";
import { Window } from "styled-icons/fluentui-system-regular";
import { UserGroup } from "styled-icons/fa-solid";
import { Star } from "styled-icons/bootstrap";
import Faqs from "../components/reusable/Faqs";
import { FaqsContainer } from "../styles/pages/executive-search";

const loreal =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Frpo%2FLOREAL.svg?alt=media&token=76d10496-dd4b-423a-874f-ba2077065f64";
const femsa =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Frpo%2FCOCA%20COLA%20FEMSA.svg?alt=media&token=70f8b1a8-0e1b-40bd-ac8a-503f59f896e3";
const solenis =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Frpo%2FSOLENIS.svg?alt=media&token=dae4f058-54d1-4e36-b2d9-0c4156a5464d";

const Rpo = () => {
  const { t } = useTranslation("rpo");

  const items = React.useMemo(
    () => [
      {
        title: t("internRecruitment1"),
        icon: <DocumentTextClock size={20} color="#003D52" />,
      },
      {
        title: t("internRecruitment2"),
        icon: <User size={20} color="#003D52" />,
      },
      {
        title: t("internRecruitment3"),
        icon: <CreditCard size={20} color="#003D52" />,
      },
    ],
    [t]
  );

  const itemsRPo = React.useMemo(
    () => [
      {
        title: t("rpo1"),
        icon: <Window size={20} color="#003D52" />,
      },
      {
        title: t("rpo2"),
        icon: <UserGroup size={20} color="#003D52" />,
      },
      {
        title: t("rpo3"),
        icon: <Star size={20} color="#003D52" />,
      },
    ],
    [t]
  );

  const faqs = React.useMemo(
    () => [
      {
        question: t("question1"),
        answer: t("answer1"),
      },
      {
        question: t("question2"),
        answer: t("answer2"),
      },
      {
        question: t("question3"),
        answer: t("answer3"),
      },
      {
        question: t("question4"),
        answer: t("answer4"),
      },
    ],
    [t]
  );

  return (
    <Layout>
      <Seo
        canonical="https://empresas.lapieza.io/rpo"
        title="Empresas LaPieza - Líderes de reclutamiento y headhunting"
        image="https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fpreviews%2FLANDING%20EMPRESAS.png?alt=media&token=3dd56d07-4544-4eef-ab5c-5b53a475137e"
      />
      <Bg.Gradient>
        <HeroSection>
          <div
            style={{
              minHeight: "500px",
            }}
            className="hero-section main-landing-container-row"
          >
            <article>
              <Text.Title
                text={t("title")}
                isH1
                fontSize="42px"
                textAlign="start"
              />
              <Text.Body
                text={t("description")}
                sx={{
                  fontSize: "18px",
                  textAlign: "start",
                  marginTop: "20px",
                  display: "block",
                }}
              />

              <Button
                href="https://api.whatsapp.com/send/?phone=527771369552&text=¡Hola Alexa! Me interesa saber más sobre los servicios de LaPieza."
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  marginTop: "50px",
                  width: "280px",
                  fontSize: "16px",
                  mb: 5,
                }}
              >
                <Text.Body
                  text={t("moreInformation")}
                  sx={{
                    color: "primary.white",
                    fontSize: "16px",
                  }}
                />
              </Button>
            </article>
            <figure>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Frpo%2FrpoHeader.png?alt=media&token=03554dbe-8b06-40d3-b17d-926192ab2fe8"
                alt="LaPieza"
              />
            </figure>
          </div>
        </HeroSection>
      </Bg.Gradient>

      <Stack padding={3} alignItems="center" justifyContent="center">
        <Text.Title>{t("companies")}</Text.Title>
      </Stack>

      <CompaniesSection>
        <div className="companies-section">
          <Carousel width="500px" logos={[loreal, femsa, solenis]} />
        </div>
      </CompaniesSection>

      <Bg.Linear>
        <Stack
          padding={3}
          alignItems="center"
          justifyContent="center"
          sx={{ color: "primary.white" }}
        >
          <Stack
            padding={{
              md: "20px 80px",
              sm: "20px",
              sx: "20px",
            }}
            width="100%"
          >
            <Text.Title>{t("whyRPO")}</Text.Title>
            <Stack alignItems="center">
              <Grid
                container
                mt={{
                  md: 10,
                  sm: 5,
                  sx: 5,
                }}
                maxWidth="1200px"
                spacing={2}
                width="100%"
              >
                <Grid item md={6} sm={12} width="100%">
                  <Stack alignItems="center" spacing={3}>
                    <Text.SubTitle>{t("internRecruitment")}</Text.SubTitle>
                    {items.map((item) => (
                      <Stack
                        bgcolor="#FFFFFF"
                        width="100%"
                        height="60px"
                        alignItems="center"
                        paddingLeft={3}
                        paddingRight={3}
                        borderRadius="10px"
                        spacing={3}
                        direction="row"
                      >
                        <Stack>{item.icon}</Stack>
                        <Text.Body textAlign="start">{item.title}</Text.Body>
                      </Stack>
                    ))}
                  </Stack>
                </Grid>
                <Grid item md={6} sm={12}>
                  <Stack alignItems="center" spacing={3}>
                    <Text.SubTitle>{t("rpo")}</Text.SubTitle>
                    <Stack alignItems="center" width="100%" spacing={3}>
                      {itemsRPo?.map((item) => (
                        <Stack
                          bgcolor="#FFFFFF"
                          width="100%"
                          height="60px"
                          alignItems="center"
                          paddingLeft={3}
                          paddingRight={3}
                          borderRadius="10px"
                          spacing={3}
                          direction="row"
                        >
                          <Stack>{item.icon}</Stack>
                          <Text.Body textAlign="start">{item.title}</Text.Body>
                        </Stack>
                      ))}
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          </Stack>
        </Stack>
      </Bg.Linear>

      <FaqsContainer>
        <div className="main-landing-container-column">
          <Text.Title
            text={t("faqsTitle")}
            sx={{
              fontSize: "24px",
              textAlign: "start",
              display: "block",
              marginBottom: "40px",
            }}
          />
          <Faqs faqs={faqs} className="faqs" />
        </div>
      </FaqsContainer>

      <ContactSection>
        <div className="main-landing-container-column">
          <Stack sx={{ maxWidth: "655px", marginBottom: "80px" }} spacing={3}>
            <Text.Title text={t("contact")} fontSize={"38px"} />
            <Text.Body fontSize="18px">{t("callToAction")}</Text.Body>
          </Stack>
          <div className="button-group">
            <Button
              href="https://api.whatsapp.com/send/?phone=527771369552&text=¡Hola Alexa! Me interesa saber más sobre los servicios de LaPieza."
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                width: "280px",
                fontSize: "16px",
                mb: 5,
              }}
            >
              <Text.Body
                text={t("moreInformation")}
                sx={{
                  color: "primary.white",
                  textAlign: "center",
                }}
              />
            </Button>
          </div>
        </div>
      </ContactSection>
    </Layout>
  );
};

export default React.memo(Rpo);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
